import React, { useContext, useState, useEffect } from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import "swiper/css"
import "swiper/css/pagination"
import Layout from "../components/layout.js"
import { graphql } from "gatsby"
import BodyWrapper from "../components/Layouts/BodyWrapper/index.js"
import LineGen from "../components/ResuableComponents/LineGen/LineGen.js"
import { GatsbyImage } from "gatsby-plugin-image"
import Footer from "../components/MediatorComponents/Footer/index.js"
import ButtonHoverNone from "../components/ResuableComponents/buttonNotHover/index.js"
import { MobileContext } from "../components/Context/mobile.js"
import Details from "../components/ResuableComponents/Details/index.js"
import useProducts from "../components/CustomHooks/fetchProducts.js"
import Seo from "../components/seo.js"
import SplitSectionHalf from "../components/Layouts/SplitSectionHalf/index.js"
import ChangePage from "../components/Context/ChangePage"
import Arrow from "../components/ResuableComponents/Arrow/index"
import { arrowActive, arrowNActive, scaleDown, scale } from "../components/Functions/fadeTransition.js"
import ReactMarkdown from "react-markdown"
import Client from "shopify-buy"
import fetch from "isomorphic-fetch"
import { CartContext } from "../components/Context/cartProvider.js"
import Cart from "../components/components/cart.js"

const Product = ({ data, location }) => {
  const optional = useProducts()
  const nextPage = data?.nextPageQuery?.edges[0]?.node ? data?.nextPageQuery?.edges[0]?.node : optional?.products.nodes[0]
  const [productVariants, setProductVariants] = useState([{}])
  const [sizes, setSizes] = useState([])
  const [colors, setColors] = useState([])
  const [selectedSize, setSelectedSize] = useState([])
  const [selectedColor, setSelectedColor] = useState([])
  const [selectedVariantId, setSelectedVariantId] = useState(0)
  const [shopifyPrice, setShopifyPrice] = useState(0)
  const nextPageName = nextPage?.name
  const nextPageSlug = `/product/${nextPage?.slug}`
  const nextPageImage = nextPage.heroimage
  const shareImage = data.product.heroimage.gatsbyImageData.src
  const { mobile } = useContext(MobileContext)
  const { name, year, description, width, depth, price, height, comingSoon, images, heroimage, totalQuantity, currentQuantity, sizingAdditionalInfo, material, cat, handmade, sizing, shopifyProductId, materialCareInfo, shippingInfo } = data.product
  const catSlug = `/${cat.slug}`
  const productId = `gid://shopify/Product/${shopifyProductId}`
  const cmsColours = { title: data.product.coloursTitle, materials: data.product.colourMaterials }
  const isAfterpayEnabled = data.afterpay.isAfterpayEnabled
  const { cart, dispatch } = useContext(CartContext)
  const [noOfItems, setNoOfItems] = useState(1)
  const afterPayPrice = (shopifyPrice * noOfItems) / 4

  // -------------------------
  //  Shopify Integration
  // -------------------------

  const shopifyStorefrontAccessToken = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
  const shopifyDomain = process.env.GATSBY_SHOPIFY_STORE_URL
  const client = Client.buildClient(
    {
      domain: shopifyDomain,
      storefrontAccessToken: shopifyStorefrontAccessToken,
    },
    fetch
  )

  // -- Fetch products
  async function getProductVariants(productId) {
    const product = await client.product.fetch(productId)
    const variants = product.variants.map(variant => {
      const title = variant.title
      const [size, color] = title.split(" / ")
      return {
        id: variant.id,
        size,
        title,
        color,
        price: variant.price.amount,
        options: variant.selectedOptions,
      }
    })
    const uniqueSizes = Array.from(new Set(variants.map(variant => variant.size)))
    const uniqueColors = Array.from(new Set(variants.map(variant => variant.color)))
    setSizes(uniqueSizes)
    setColors(uniqueColors)
    setShopifyPrice(variants[0].price)
    setSelectedColor(colors[0])
    setSelectedSize(sizes[0])
    setSelectedVariantId(variants[0].id)
    return variants
  }

  // -- Find the variant
  function findVariant(selectedSize, selectedColor) {
    if (productVariants && productVariants.length > 0) {
      const variant = productVariants.find(variant => {
        return variant.size === selectedSize && variant.color === selectedColor
      })
      return variant ? variant : null
    }
    return null
  }

  // -- Add to cart
  function addToCart() {
    const title = name
    const image = heroimage.gatsbyImageData
    const price = shopifyPrice
    const id = productId
    const variantId = selectedVariantId
    const variantName = selectedColor && selectedSize ? `${selectedColor} / ${selectedSize}` : ""
    const cartDiv = document.querySelector(".cart")
    const overlay = document.querySelector(".overlay")
    const alreadyExist = cart.find(item => item.variantId === selectedVariantId)
    cartDiv?.classList.remove("closed")
    overlay?.classList.remove("hidden")
    if (alreadyExist) {
      cart.forEach(item => {
        if (item.variantId === selectedVariantId) {
          dispatch({ type: "UPDATE_QUANTITY", variantId, quantity: item.quantity + noOfItems })
        }
      })
    } else {
      dispatch({
        type: "ADD_ITEM",
        title,
        price,
        id,
        image,
        variantId,
        variantName,
        quantity: noOfItems,
      })
    }
  }

  // -- Set state after fetch
  useEffect(() => {
    getProductVariants(productId)
      .then(variants => {
        setProductVariants(variants)
        setSelectedColor(variants[0].color)
        setSelectedSize(variants[0].size)
      })
      .catch(error => {
        console.error(error)
      })
  }, [productId])

  // -- Update variant based on size and colour selection
  useEffect(() => {
    if (productVariants.length > 0) {
      const selectedVariant = findVariant(selectedSize, selectedColor)
      setShopifyPrice(selectedVariant ? selectedVariant.price : productVariants[0].price)
      setSelectedVariantId(selectedVariant ? selectedVariant.id : productVariants[0].id)
    }
  }, [selectedColor, selectedSize, productVariants])

  const Row = ({ left, right }) => {
    const [isCollapsed, setIsCollapsed] = useState(true)
    useEffect(() => {
      var fixedElements = document.getElementById("fixed-elements2")
      const updatePadding = () => {
        if (fixedElements.scrollHeight > fixedElements.clientHeight) {
          fixedElements.style.paddingBottom = "40%"
          if (window.innerHeight > 900) {
            fixedElements.style.paddingBottom = "25%"
          }
          if (window.innerHeight < 720) {
            fixedElements.style.paddingBottom = "35%"
          }
          if (window.innerHeight > 1000 && window.innerWidth > 1600) {
            fixedElements.style.paddingBottom = "15%"
          }
          if (window.innerWidth < 990) {
            fixedElements.style.paddingBottom = "0%"
          }
        }
        const accordions = document.querySelectorAll(".row-wrapper")
        let totalCollapsed = 0
        accordions.forEach(element => {
          if (element.classList.contains("collapsed")) {
            totalCollapsed += 1
          }
        })
        if (totalCollapsed === accordions.length) {
          fixedElements.style.paddingBottom = "160px"
        }
      }

      updatePadding()
      window.addEventListener("resize", updatePadding)
      return () => {
        window.removeEventListener("resize", updatePadding)
      }
    }, [isCollapsed])

    return (
      <div className={`p-c row-wrapper ${isCollapsed ? "collapsed" : "open"}`} onClick={() => setIsCollapsed(previousState => !previousState)}>
        <LineGen numberOfLines={1} />
        <div className='flex flex-c p-t-15 pb-15 p-t-15-br-s flex-sb align-center'>
          <div className={`w-100 flex justify-between  align-center ml-a pr-10 ${isCollapsed ? "pb-0" : "pb-10"}`}>
            {left} <div className={`icon ${isCollapsed ? "closed" : "open"}`} />
          </div>
          {!isCollapsed && <div className={`m-w-260 row-content flex-grow-1 m-w-210-br ml-a mr-15 mb-10 w-100`}>{right}</div>}
        </div>
      </div>
    )
  }

  const [formState, setFormState] = useState(false)
  const [notifyState, setNotifyState] = useState(false)

  const handleClick = (e, formControl, flag) => {
    if (e) {
      e.preventDefault()
    }
    if (typeof flag !== "undefined") {
      return formControl(flag)
    }
    return formControl(state => !state)
  }

  return (
    <>
      <Cart />
      <SideMenu name={name} notify={true} year={year} heroimage={heroimage} price={price} formState={formState} setFormState={setFormState} />
      <SideMenu name={name} notify={false} year={year} heroimage={heroimage} price={price} formState={notifyState} setFormState={setNotifyState} />
      <Layout location={location}>
        <Seo title={name} description={description} />
        <BodyWrapper className='p-t-15  c-15' data-scroll-section>
          <div id='fb-root'></div>
          <script async defer crossorigin='anonymous' src='https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v11.0' nonce='2EsS9K5W'></script>
          <div className='flex flex-r flex-sb p-b-300 p-t-42-br p-b-110-br'>
            <h2>{name}</h2>
            <h2>{`${year}`}</h2>
          </div>
          <div className='p-b-160 p-b-200 p-t-15 p-t-15-br-u flex flex-c re' id={"fixed-elements"}>
            <div className='flex flex-c h-100' id={"fixed-elements2"} style={{ height: `${images.length}` }}>
              <div className='flex flex-r flex-grow-1 flex-c-br wp' data-scroll data-scroll-call='fadeInText' data-scroll-offset='100%' data-scroll-repeat='true'>
                <div className='w-50p o-2 w-100p-br  flex flex-c   h-u p-b-0-br sticky-wrapper' data-scroll-sticky={!mobile ? true : false} data-scroll data-scroll-target='#fixed-elements2'>
                  <div className='d-n-br'>
                    <div className={"h-45 h-45-br flex flex-r flex-sb flex-center-v fade-in pb-10 fade p-0 "}>
                      <div className='flex flex-c-h flex-c'>
                        <h3>{name}</h3>
                      </div>
                      <div className='flex  flex-c-h flex-c m-w-260 w-100vw flex-al-s mr-15 '>
                        <h3> {`${year}`}</h3>
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-r  m-b-30 m-b-15-br flex-grow-1 p-t-30-br flex-c-br'>
                    <div className='flex-grow-1 m-b-20-br'>
                      <h3>DES.</h3>
                    </div>
                    <div className='m-w-300 flex flex-c flex-sb mr-25 m-100p'>
                      <div className='btn-wrapper'>
                        <h3 className='mb-15 '>{name}</h3>
                        {shopifyPrice && (
                          <div className='price flex-c-br flex-br'>
                            <p>AU ${(shopifyPrice * noOfItems).toLocaleString("en")}</p>
                            {isAfterpayEnabled && (
                              <p className='afterpay-price'>
                                {`4 x AU $${afterPayPrice} via`}{" "}
                                <a target='_blank' href='https://www.afterpay.com/en-AU' rel='noreferrer'>
                                  Afterpay
                                </a>
                              </p>
                            )}
                          </div>
                        )}
                        <hr className='mb-15 opacity-50' />
                        <ReactMarkdown className='m-b-30 m-w-270-br p-br '>{description}</ReactMarkdown>
                        {colors.length > 1 ? (
                          <div className='flex flex-c color-wrapper'>
                            <p>Select a colour:</p>
                            <div className='flex flex-r'>
                              {colors.map(color => (
                                <button
                                  key={color}
                                  className={`color-selector ${selectedColor === color ? " active" : ""}`}
                                  data-css={`${!selectedColor && "first-selected"}`}
                                  onClick={() => {
                                    setSelectedColor(() => color)
                                  }}
                                >
                                  {color}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : null}
                        {sizes.length > 1 ? (
                          <div className='flex flex-c size-wrapper'>
                            <p>Select a size:</p>
                            <div className='flex flex-r'>
                              {sizes.map(size => (
                                <button
                                  key={size}
                                  data-css={`${!selectedSize && "first-selected"}`}
                                  className={`size-selector ${selectedSize === size ? " active" : ""}`}
                                  onClick={() => {
                                    setSelectedSize(() => size)
                                  }}
                                >
                                  {size}
                                </button>
                              ))}
                            </div>
                          </div>
                        ) : null}

                        {shopifyPrice && (
                          <div className='update-quantity mb-15 product-page'>
                            <p>QTY.</p>
                            <button disabled={noOfItems === 1} onClick={() => setNoOfItems(prev => prev - 1)}>
                              -
                            </button>
                            {noOfItems}
                            <button onClick={() => setNoOfItems(prev => prev + 1)}>+</button>
                          </div>
                        )}
                        {productVariants.length >= 1 && shopifyProductId && (
                          <button name='Buy Now' className='w-100p black-hover enquire-button flex al-c-i buy-now' onClick={() => addToCart()}>
                            ADD TO CART
                          </button>
                        )}
                        {comingSoon ? <ButtonHoverNone name='Notify' className='w-100p black-hover enquire-button   flex al-c-i m-b-170-br' onClick={e => handleClick(e, setFormState)} /> : <ButtonHoverNone name='enquire' className='w-100p black-hover enquire-button  flex al-c-i m-b-170-br' onClick={e => handleClick(e, setNotifyState)} />}
                      </div>

                      <div>
                        <div>
                          <h4>{handmade}</h4>
                          <p>{data.product.madeIn ? data.product.madeIn : `All pieces are made in small batches, and in limited releases`}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <section className='rows-wrapper'>
                    {width && depth && height ? (
                      <Row
                        left={<h3>Dimens.</h3>}
                        right={
                          <p>
                            {width}MM (W) X {depth}MM (L) X {height}MM (H)
                          </p>
                        }
                      />
                    ) : null}
                    {sizing.length > 1 ? (
                      <Row
                        left={<h3>Sizing.</h3>}
                        right={
                          <div className='sizing-table'>
                            <div className='sizing-header'>
                              <p> _ </p> <p>W(cm)</p>
                              <p>L(cm)</p>
                            </div>
                            {sizing.map(({ size, width, length }) => (
                              <div className='sizing-row'>
                                <p>{size}</p>
                                <p>{width}</p>
                                <p>{length}</p>
                              </div>
                            ))}
                            {sizingAdditionalInfo && <p>{sizingAdditionalInfo}</p>}
                          </div>
                        }
                      />
                    ) : null}
                    {cmsColours.title || cmsColours.materials.length >= 1 ? (
                      <Row
                        left={<h3>Colours.</h3>}
                        right={
                          <div className='cmsColours-wrapper'>
                            <p>{cmsColours.title}</p>
                            <div className='cmsColoursMaterials'>
                              {cmsColours.materials.map(material => (
                                <div className='colourMaterial'>
                                  <GatsbyImage image={material.gatsbyImageData} />
                                </div>
                              ))}
                            </div>
                          </div>
                        }
                      />
                    ) : null}
                    {currentQuantity && totalQuantity ? (
                      <Row
                        left={<h3>Avl. Bat.</h3>}
                        right={
                          <div className='flex flex-r'>
                            <p className='m-r-20'>{currentQuantity}</p> <p>/{totalQuantity}</p>
                          </div>
                        }
                      />
                    ) : null}
                    {shopifyPrice ? (
                      <Row
                        left={<h3>Shipping.</h3>}
                        right={
                          <div className='price-wrapper'>
                            <p>{shippingInfo ? `* ${shippingInfo}` : ""}</p>
                            <Link to={"/policies"}>
                              <ButtonHoverNone name='SHIPPING AND RETURNS' />
                            </Link>
                          </div>
                        }
                      />
                    ) : null}
                    {material ? (
                      <Row
                        left={<h3>MAT.</h3>}
                        right={
                          <div>
                            <p>{material}</p>
                            <div className='careInfo-wrapper'>{materialCareInfo ? materialCareInfo.map(({ info }) => <p key={info}>* {info}</p>) : null}</div>
                          </div>
                        }
                      />
                    ) : null}
                    <Row
                      left={<h3>SHR.</h3>}
                      right={
                        <div className='flex flex-r'>
                          <div className='flex flex-r flex-c-c' data-href='https://developers.facebook.com/docs/plugins/' data-layout='button' data-size='small'>
                            <a rel='noreferrer noopener' target='_blank' href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse' class='fb-xfbml-parse-ignore'>
                              {" "}
                              <ButtonHoverNone name='SHARE' />
                            </a>
                          </div>
                          <div className='flex flex-r flex-c-c m-l-15' data-href='https://developers.facebook.com/docs/plugins/' data-layout='button' data-size='small'>
                            <a rel='noreferrer noopener' target='_blank' data-pin-do='buttonPin' data-pin-count='above' data-pin-custom='true' href={`https://www.pinterest.com/pin/create/button/?url=${location}F&media=${shareImage}.jpg&description=${description}`}>
                              {" "}
                              <ButtonHoverNone name='PINTERST' />
                            </a>
                          </div>
                        </div>
                      }
                    />
                    {mobile ? <LineGen numberOfLines={1} /> : null}
                  </section>
                </div>
                <div className='w-50p w-100p-br o-1 p-l-15 p-t-45 flex flex-c p-l-15-br-u p-t-45-br-u'>
                  <div className=' w-100p ab z-4 ' data-scroll-sticky data-scroll data-scroll-target='#fixed-elements2'></div>
                  {images.map(image => {
                    return (
                      <>
                        <div className='m-hide z-3 p-b-30-s m-w-800-br m-l-a-br m-r-a-br'>
                          <GatsbyImage alt='' image={image.gatsbyImageData} className='h-100p w-100p' />
                        </div>
                      </>
                    )
                  })}
                  <Swiper modules={[Pagination]} pagination={{ clickable: true }} spaceBetween={30} slidesPerView={1} className='m-show swiper-product-carousel'>
                    {images.map(image => (
                      <SwiperSlide className='carousel-slide'>
                        <GatsbyImage alt='' image={image.gatsbyImageData} className='' />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
          <SplitSectionHalf
            className=' m-t-100-br mt-15 b-t-1-g-br p-t-15-br'
            innerClassName='top-grey'
            right={
              <ChangePage
                to={nextPageSlug}
                className={"flex flex-r p-l-0-br p-l-15 w-100p re h-100p flex-sb "}
                onMouseEnter={e => {
                  arrowActive(e.target)
                  scale(e.target)
                }}
                onMouseLeave={e => {
                  arrowNActive(e.target)
                  scaleDown(e.target)
                }}
              >
                <div className='h-100p re n-e w-100p m-h-270 h-100vh m-h-175-br'>
                  <div className={"flex  flex-c w-100p h-100p flex-sb "}>
                    <div className={"flex flex-c p-b-30-br " + (mobile ? "flex-sb" : "")}>
                      <h4 className='m-b-10'>Next Product</h4>
                      {mobile ? <h3 className='m-w-212-br m-w-75-br'>{nextPageName}</h3> : null}
                      <div className='flex flex-r al-i-c-br flex-sb al-i-s-br'>{!mobile ? <h3 className='m-w-212-br '>{nextPageName}</h3> : null}</div>
                    </div>
                    <div>
                      <Arrow />
                    </div>
                  </div>
                </div>
                <div className='m-h-270 h-100vh h-100p m-h-175-br m-w-245 m-w-150br'>
                  <div className='re o-h h-100p'>
                    <img alt='' src={nextPageImage?.url} style={{ height: "100%" }} objectPosition='100% 100%' className='enlarge p-e-n h-100p' />
                  </div>
                </div>
              </ChangePage>
            }
            left={
              !mobile ? (
                <ChangePage to={catSlug}>
                  <div className='flex flex-r button-tran'>
                    <div className='flex-center-v flex flex-c center-h  '>
                      <svg width='3' height='7' viewBox='0 0 3 7' fill='none' xmlns='http://www.w3.org/2000/svg' className='m-r-15 move-s-r'>
                        <path d='M1.5299e-07 3.5L3 6.53109L3 0.468911L1.5299e-07 3.5Z' fill='#485E4C' />
                      </svg>
                    </div>

                    <h4 className=''>All products</h4>
                  </div>
                </ChangePage>
              ) : null
            }
          />
          <Footer location={location} />
        </BodyWrapper>
        <script async defer src='//assets.pinterest.com/js/pinit.js'></script>
      </Layout>
    </>
  )
}

function SideMenu({ formState, setFormState, handleClick, heroimage, price, name, year, notify = true }) {
  const closeForm = e => {
    e.preventDefault()

    setFormState(false)
    setTimeout(() => {
      setSubmit(false)
    }, 1000)
  }

  const [submit, setSubmit] = useState(false)
  const baseClass = "m-w-450 re l-grey h-100vh flex tran-an  t-0 r-0  flex flex-r flex-grow-1 z-100 back-white-off p-r-40 p-l-40 p-t-30 "
  const classOpen = baseClass + "tran-0"
  const classClosed = baseClass + "tran-100p-p"

  const handleSubmit = e => {
    e.preventDefault()
    let myForm = e.target.closest("form")
    let formData = new FormData(myForm)
    const dataName = formData.get("name"),
      email = formData.get("email"),
      phone = formData.get("phone")
    if (dataName && email && phone) {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
        .then(() => {
          setSubmit(true)
        })
        .catch(error => console.error(error))
    }
  }

  return (
    <form className={"flex flex-r o-s m-h-100vh  w-100vw fi z-100 flex al-e  " + (!formState ? "tran-100p-p d-1" : "tran-0 ")} id='form2' name={!notify ? "enquiry" : "notify"} method='POST' data-netlify='true'>
      <button
        onClick={e => {
          closeForm(e)
        }}
        className='flex-grow-1 h-100vh ab w-100p h-100p z-99'
        style={{ cursor: "default" }}
      ></button>
      <div className={!formState ? classClosed : classOpen}>
        <div className='flex-grow-1 flex-se flex-c flex al-s o-s'>
          <div className='w-100p '>
            <div className='b-t-grey p-b-12 p-t-10 flex flex-c '>
              <div className='flex flex-r re'>
                <div className='re flex-grow-1 m-w-75'>
                  <div className=' w-100p w-100vw m-w-85 o-h ' style={{ paddingTop: "113.33%" }}>
                    <GatsbyImage alt='' image={heroimage.gatsbyImageData} className='ab t-0' />
                  </div>
                </div>
                <div className='flex flex-grow-1 p-l-15 flex-r  '>
                  <div className='flex flex-c flex-sb w-100p'>
                    <div className='flex flex-c'>
                      <input readOnly name='product-name' value={name} className='p-b-3 h5 no-input' placeholder={name} />
                      <h5 className='upperCase'>No.{year}</h5>
                    </div>
                    {!notify ? <div>{price ? <input name='product-price' className='h5 no-input' readOnly placeholder={`$${price.toLocaleString("en")}`} /> : null}</div> : null}
                  </div>
                  <div className='flex-grow-1 flex al-e  al-i-s'>
                    <button
                      onClick={e => {
                        closeForm(e)
                      }}
                    >
                      <h4> [X]</h4>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <LineGen numberOfLines={1} />
          </div>
          <div className=' flex flex- flex-c flex-grow-1 p-b-30 al-e re'>
            <div className='flex flex-c  w-100p  flex flex-c-h z-49 re h-100p w-100p m-t-a m-b-a m-w-450  '>
              <div className={"flex flex-c w-100p flex-grow-1  " + (!notify ? " m-h-300" : "")}>
                <div className='re w-100p flex-grow-1 flex  center-h '>
                  <div className={" flex  w-100p h-100p ab  fade-1 " + (submit ? "p-0 p-e-n" : "p-1 z-4")}>
                    <div className={"flex flex-c w-100p flex-grow-1 h-100p re  " + (!notify ? "h-200" : "flex-c-h")}>
                      <input type='hidden' name='form-name' value={!notify ? "enquiry" : "notify"} />
                      {notify ? <p>Join us for upcoming releases. </p> : null}
                      <input type='text' name='name' placeholder='name*' />

                      {!notify ? (
                        <select name='EnquiryType'>
                          <option value='' disabled selected>
                            Enquiry Type
                          </option>
                          <option value='General'>General</option>
                          <option value='Sales Enquiry'>Sales Enquiry</option>
                        </select>
                      ) : null}
                      <input type='text' name='email' placeholder='email*' />
                      <input type='text' name='phone' placeholder='Phone*' />
                      {!notify ? <textarea type='text' name='message' className='flex-grow-1 w-100p' placeholder='Message...' /> : null}
                      <div className={"flex flex-r flex-sb w-100p " + (notify ? "p-t-15" : "")}>
                        <p>{!notify ? `We’ll get back to you shortly.` : `Join the waitlist`} </p>{" "}
                        <button aria-label='submit' onClick={e => [handleSubmit(e, setFormState)]}>
                          <ButtonHoverNone name='submit' />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={"flex flex-c w-100p center-h  " + (submit ? "p-1 fade-1-1 " : "p-0")}>
                    <p className='input m-b-20'>Your enquiry has been received.</p>
                    <button
                      onClick={e => {
                        closeForm(e)
                      }}
                      type='button'
                    >
                      <ButtonHoverNone name='   keep exploring' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className='p-t-15'>
              <Details />
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}

export default Product

export const query = graphql`
  query product($slug: String!, $skip: Int!, $limit: Int!) {
    afterpay:  datoCmsStoreOption {
      isAfterpayEnabled
    }
    product: datoCmsProduct(slug: { eq: $slug }) {
      name
      slug
      launchDate
      shopifyProductId
      sizing {
        size
        width
        length
      }
      sizingAdditionalInfo
      coloursTitle
      colourMaterials {
        gatsbyImageData
      }
      materialCareInfo {
        info
      }
      material
      shippingInfo
      year
      madeIn
      description
      width
      depth
      comingSoon
      height
      totalQuantity
      currentQuantity
      material
      handmade
      heroimage {
        gatsbyImageData(placeholder: DOMINANT_COLOR)
      }
      images {
        gatsbyImageData(placeholder: DOMINANT_COLOR)
      }
      cat {
        slug
      }
    }
    nextPageQuery: allDatoCmsProduct(limit: $limit, skip: $skip) {
      edges {
        node {
          name
          slug
          heroimage {
            gatsbyImageData(placeholder: DOMINANT_COLOR)
            url
          }
        }
      }
    }
  }
`
